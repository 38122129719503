.search-wrap {
  margin-top: 40px;
  margin-bottom: 50px;
  position: relative;
  &::after {
    content: "";
    background: url("../../images/icons/search.svg");
    width: 17px;
    height: 17px;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 10px;
  }
  .form-control {
    border: none;
    border-bottom: 2px solid $gray-2;
    border-radius: 0;
    padding-left: 25px;
  }
  @media (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 35px;
  }
}
