$black-1: #202027;
$black-2: #707070;
$black-3: #1e1a22;
$white: #ffffff;

$gray-1: #e8e8e8;
$gray-2: #f4f4f4;
$gray-3: #979797; // text
$gray-4: #dddddd; //border
$gray-5: #f6f6f6; //border and button

$label: #878a98; // Form Label
