.title-bar {
  background-color: $gray-1;
  color: $black-1;
  max-height: 106px;
  font: normal normal 600 28px/35px Montserrat;
  padding: 36px 0px;
  @media (max-width: 767px) {
    font: normal normal 600 14px/11px Montserrat;
    padding: 17px 0px;
  }
}
