.search-main {
  input {
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 5px;
  }
  ::placeholder{
    font-size: 15px;
  }
}
.active{
  box-shadow: 0px 0px 26px -12px #888888 !important;
}
.tumb-img{
  border-radius: 0px !important;
  max-height: 100%;
  object-fit: contain;
}
.product-box-image{
  height: 350px;
  display: flex;
  @media (max-width :1200px) {
    height: 320px;
  }
}
.product-box{
  margin-bottom: 0px !important;
}
.custom-checkbox input {
  min-width: 23px; 
  height: 23px;
}
.custom-checkbox input:disabled ,.custom-checkbox input:checked{
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: #202027 !important;
}
.search-wrap {
  margin-top: 40px;
  margin-bottom: 50px;
  position: relative;
  &::after {
    content: "";
    background: url("../../images/icons/search.svg");
    width: 17px;
    height: 17px;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 7px !important;
  }
  .form-control {
    border: none;
    border-bottom: 2px solid gray;
    border-radius: 0;
    padding-left: 25px;
  }
}
.modal .modal-full {
  max-width: 100%;
  height: 100%;
  margin: 0; 
}
.modal{
  padding: 0px !important;
}
.close-icon{
  position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    border: none;
}
.cursor-pointer{
  cursor: pointer;
}

.search-sm.search-main .react-tagsinput > span, .search-xs.search-main .react-tagsinput > span {
  font-family: "Montserrat", sans-serif;
  padding: 0 0 0 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.search-sm.search-main .react-tagsinput > span {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.search-sm.search-main .react-tagsinput > span .react-tagsinput-tag, .search-xs.search-main .react-tagsinput > span .react-tagsinput-tag {
  font-family: "Montserrat", sans-serif;
  padding: 1px 10px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .02rem;
  margin: 2px;
}
.react-tagsinput-tag {
  background: black;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: white;
  border: initial;
  
}
.react-tagsinput{
  border-bottom: 1px solid #F4F4F4 !important;
}
.react-tagsinput-tag a::before {
  content: " ×";
  color: white;
}
.react-tagsinput-tag a{
  text-decoration: none !important;
  cursor: pointer;
}
.folder-product-left-nav , .folder-product-right-nav{
  padding: 14px 15px;
  background: transparent;
  border: 1px solid #DDDDDD;
  svg{
    color: #707070;
    width: 20px;
    height: 20px;
    stroke-width:0.5px;
  }
}
.folder-product-left-nav{
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translate(-50% , -50%);

  @media (max-width:1024px) {
  top: 90%;
  left: 35%;
  transform: translateX(-50%);
  }
}

.folder-product-right-nav{
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50% , -50%);
  @media (max-width:1024px) {
    top: 90%;
      right: 15%;
  transform: translateX(-50%);

    }

}

.company-logo {
  justify-content: flex-start;

  @media (max-width:576px) {
    justify-content: center !important;
  }
}
.displayOptions {
  display: block !important;
}
.product-details-body {
  @media (max-width:576px) {
      height: calc(73vh - 88px) !important;
    }
}
.folder-product-modal{
  display: flex;
  justify-content: center;
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  height: 90vh;
  @media (max-width:1440px) {
    width: 600px;
  }
  @media (max-width:850px) {
    width: 350px !important;
  }
  @media (max-width:576px) {
      height: 55vh !important;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    // @media (max-width :1024px) {
    //   width: 500px;
    // }
  }
}
.form .form-label{
  font-size: 12px !important;
  margin-bottom: 0px !important;
  margin-top: 15px !important;
}
.cart-main{
  flex-direction: column;
  height: calc(100% - 75px);
  justify-content: space-between;
}
.empty-message{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.selections{
  z-index: 9999 !important;
}
.folder-product-details{
  width: 100% !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  height: 100vh;
  overflow: hidden;
  @media (max-width:576px){
    overflow: inherit !important;
    height: 72vh !important;
  }
}
.folder-product-images{
  position: relative;
}
.modal-content{
  border: 0px !important;
  border-radius: 0px !important;
}
.product-details-title, .selections-product-title{
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.selections-product-title{
  max-width:280px ;
  @media (max-width:1350px) {
    max-width: 230px;
  }

}
.product-modal-viewer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.over-flow-hidden{
  overflow: hidden;
}
.over-flow-scroll{
  overflow: scroll !important;
}

.comment-modal{
z-index: 99999 !important;
}

.angle-card-img{
  max-width: 100% !important;
  max-height: 100% !important;
}
.slick-slider{
  max-width: 400px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
}
.angle-card{
  width: 120px !important;
  height: 80px !important;
  object-fit: contain;
  cursor: pointer;
}

.slick-prev:before, .slick-next:before{
  color: black !important;
  font-size: 25px !important;
}
.slick-slide{
  display: flex !important;
  justify-content: center !important;
}
.slick-prev{
  left: -35px !important;
}
.slick-next{
  right: -35px !important;
}
.ReactModal__Overlay{
z-index: 9999 !important;
}
.product-drop-zone{
  border-radius: 6px;
  align-items: center;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  position: relative;
}
.text-warp{
  text-wrap:nowrap;
}
.attach-file-name{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap:nowrap;
}
.dz-message{
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: none;
}
.dropzone{
  width: 100%;
  height: 100%;
  z-index: 999;
}
.file-name-icon{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
}

.attachment-icon{
  font-size: 22px;
}

// .dropzone {
//   min-height: 115px;
//   border: 1px solid #d7d7d7;
//   background: white;
//   padding: 10px 10px;
//   border-radius: 0.1rem;
//   color: #212121;
//   height: auto; }

.dz-preview, .dz-image-preview{
      display: none !important;
  }
  .file_name{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    @media (max-width:1100px) {
      width: 100px;
    }
    @media (max-width:700px) {
      width: 250px;
    }
    @media (max-width:576px) {
      width: 150px;
    }
    @media (max-width:817px) and (min-width: 700px) {
      width: 100px !important;
    }
  }
  .MuiTimelineDot-root, .MuiTimelineDot-filled, .MuiTimelineDot-filledSecondary, .css-1v41xfw-MuiTimelineDot-root{
    background-color: black!important;
  }

  .product-details-header{
    position: relative;
  }

  .btn-success{
    font-size: 13px !important;
    color: white !important;
    padding: 15px 30px !important;
  }

  .attachment-save-btn-col{
    @media (max-width:575px) {
      width: 30% !important;
    }
  }
  .attachment-drop-col{
    @media (max-width:575px) {
      width: 70% !important;
    }
  }
  .lg-hide{
    display: none;
    margin-top: 15px;
    @media (max-width:575px) {
     display: block !important; 
     display: flex !important;
     justify-content: center;
    }
  }
  .sm-hide{
    @media (max-width:575px) {
     display: none !important; 
    }
  }
  .product-details-tagline{
    @media (max-width:575px) {
    display: flex !important;
    justify-content: center !important;
  }
   }
  .logo{
    @media (max-width:575px) {
    width: 100px !important;
    }
  } 

  .form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after{
    background-color: transparent !important;
  }
  .selections-product-comment{
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 450px;
    margin-bottom: 5px!important;
    @media (max-width: 1400px) {
      max-width: 350px
    }
    @media (max-width: 1200px){
      max-width: 200px
    }
    @media (max-width: 768px) {
      max-width: 350px
    }
    @media (max-width: 576px) {
      max-width: 200px
    }
  }