@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.form {
  &-title {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .form-label {
    font-size: 10px;
    color: black;
  }
  .form-control {
    border: 1px solid $gray-1;
    border-radius: 2px;
    font-size: 12px;
    height: 45px;
    padding: 12px;
    @include placeholder {
      font-size: 12px;
    }
  }
  .btn {
    margin-top: 30px;
  }
  .form-floating {
    .form-control {
      padding: 12px;
      height: auto !important;
    }
  }
}
