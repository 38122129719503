.product-images-wrap {
  .image-gallery {
    &-thumbnails {
      padding-top: 10px;

      @media (min-width: 992px) {
        max-width: 300px;
        margin: 0 auto;
      }

      &-container {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-thumbnail {
      border: 1px solid #dddddd;

      &.active {
        border: 1px solid #202027;
      }
    }

    &-slide {
      img {
        width: 100%;
        height: 100%;
      }
      @media (min-width: 576px) {
        img {
          max-height: calc(100vh - 350px) !important;
        }
      }

      @media (min-width: 992px) {
        img {
          max-height: calc(100vh - 350px) !important;
        }

        &-wrapper {
          height: calc(100vh - 130px);
          display: flex;
          align-items: center;
        }
      }

      @media (min-width: 576px) and (max-width: 991px) {
        img {
          max-height: calc(100vh - 585px) !important;
          width: calc(100vw - 385px) !important;
        }

        &-wrapper {
          height: calc(100vh - 150px);
          display: flex;
          align-items: center;
        }
      }
    }

    .fullscreen {
      .image-gallery-slide img {
        width: 100%;
        height: 100%;
      }
    }

    &-fullscreen-button {
      bottom: 12px;
      padding: 8px;
      background: #fff;
      right: 12px !important;
      border-radius: 2px;

      svg {
        stroke: $black-1;
        width: 30px;
        height: 30px;
      }

      @media (max-width: 767px) {
        padding: 6px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px;
    transform: inherit;
    width: 29px;
    height: 29px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    top: inherit;
    margin-bottom: -70px;
    filter: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    cursor: pointer !important;

    @media (max-width: 767px) {
      margin-bottom: -60px;
    }


    svg {
      height: 15px;
      width: auto;
      stroke: #202027;
    }

    .image-gallery-left-icon,
    .image-gallery-right-icon {
      color: black !important;
    }
  }

  .image-gallery-right-nav {
    position: absolute !important;
    bottom: 0% !important;
    // right: 24% !important;
    right: 450px !important;
  }

  .image-gallery-left-nav {
    left: 450px !important;
  }

  // @media (max-width:10px) {
  //   .image-gallery-right-nav {
  //     right: 260px !important;
  //   }
  //   .image-gallery-left-nav {
  //     left: 260px !important;
  //   }
  // }
  @media (max-width:1300px) {
    .image-gallery-right-nav {
      right: 90px !important;
    }

    .image-gallery-left-nav {
      left: 90px !important;
    }
  }

  @media (max-width:992px) {
    .image-gallery-right-nav {
      right: 110px !important;
    }

    .image-gallery-left-nav {
      left: 110px !important;
    }
  }

  @media (max-width:575px) {
    .image-gallery-right-nav {
      right: 0 !important;
      // right: 0 !important;
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .product-images-wrap .image-gallery-fullscreen-button {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait)  {
    .product-images-wrap .image-gallery-fullscreen-button {
      display: none !important;
    }
  }

  .image-gallery-left-nav {
    position: absolute !important;
    bottom: 0% !important;
    // left: 24% !important;
  }


  @media (max-width:575px) {
    .image-gallery-left-nav {
      left: 0 !important;
      // right: 0 !important;
    }
  }

  .fullscreen {

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      display: none;
    }
  }
}

.single-product-display{
    display: flex;
    justify-content: center;
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    height: 90vh;
    @media (max-width:1440px) {
      width: 600px;
    }
    @media (max-width:850px) {
      width: 350px !important;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }