.enquire-modal {
  .modal-header {
    padding: 25px 25px 0px;
    border: none;

    .modal-close {
      position: absolute;
      right: 20px;
      text-align: center;
      font-size: 14px;
      color: $black-1;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .modal-dialog {
    max-width: 630px;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 15px 48px 40px;
    @media (max-width: 767px) {
      padding: 40px 15px;
    }
  }
  &-product {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    img {
      width: 87px;
      height: 87px;
      object-fit: cover;
      margin-right: 10px;
      @media (max-width: 767px) {
        width: 76px;
        height: 76px;
      }
    }
    &-details {
      &-title {
        font-size: 14px;
        color: $black-1;
        font-weight: 500;
        margin-bottom: 8px;
      }
      &-subtitle {
        font-size: 11px;
        color: $black-1;
        margin-bottom: 8px;
        font-weight: 500;
        span {
          font-weight: 200;
        }
      }
    }
  }
  &-form {
    &-title {
      font-size: 14px;
      color: $black-1;
      text-transform: uppercase;
    }
  }
}

.add-modal {
  .modal-dialog{
    box-shadow: 0px 0 20px rgba(0, 0, 0, .3);
  }
  .modal-header {
    padding: 20px;
    .m-auto {
      font-weight: 500;
    }
    .modal-close svg {
      position: absolute;
      right: 20px;
      text-align: center;
      font-size: 14px;
      color: $black-1;
      font-weight: 500;
      cursor: pointer;
      top: 25px;
      padding: 5px;
      width: 20px;
      height: 20px;
    }
  }
  .modal-dialog {
    max-width: 924px;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 30px 72px 48px;
    img {
      width: 237px;
      height: 237px;
      object-fit: cover;
      margin-right: 24px;
      border: 1px solid $gray-5;
    }
  }
  &-product {
    margin-bottom: 35px;
    display: flex;
    &-details {
      &-title {
        font-size: 18px;
        font-weight: 500;
        color: $black-1;
        margin-bottom: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    .modal-header {
      span {
        left: 15px;
        text-align: left;
      }
    }
    .modal-body {
      // padding: 25px 15px 40px;
      img {
        margin: 0 auto;
        margin-bottom: 25px;
      }
    }
    &-product {
      flex-direction: column;
      margin-bottom: 20px;
      &-details {
        &-title {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.share-modal {
  .modal-header {
    padding: 20px;
    .m-auto {
      font-weight: 500;
    }
    .modal-close svg {
      position: absolute;
      right: 20px;
      text-align: center;
      font-size: 14px;
      color: $black-1;
      font-weight: 500;
      cursor: pointer;
      top: 25px;
      padding: 5px;
      width: 20px;
      height: 20px;
    }
  }
  .modal-dialog {
    max-width: 750px;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 30px 72px 48px;
    img {
      width: 237px;
      height: 237px;
      object-fit: cover;
      margin-right: 24px;
      border: 1px solid $gray-5;
    }
  }
  &-product {
    margin-bottom: 35px;
    display: flex;
    &-details {
      &-title {
        font-size: 18px;
        font-weight: 500;
        color: $black-1;
        margin-bottom: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    .modal-header {
      span {
        left: 15px;
        text-align: left;
      }
    }
    .modal-body {
      // padding: 25px 15px 40px;
      img {
        margin: 0 auto;
        margin-bottom: 25px;
      }
    }
    &-product {
      flex-direction: column;
      margin-bottom: 20px;
      &-details {
        &-title {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.thanks-modal-header{
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  padding: 0px;
  span{
    margin-right: 30px;
    margin-top: 25px;
  }
}
.thanks-modal-body{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
  span{
    margin: 10px 0px;
  }
  p{
    font-size: 12px;
    text-align: center;
    span{
      font-weight: 500;
    }
  }
}