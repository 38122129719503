.product {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  @media (max-width:575px) {
    // display: block;
    height: unset;
  }

  &-images {
    width: calc(100% - 435px);
    display: flex;
    align-items: center;
    justify-content: center;
    //overflow-y: auto;
    position: relative;
    @media (max-width:576px) {
      display: none;
    }

    // .image-gallery,
    // .image-gallery-content {
    //   padding: 115px 165px;
    // }
    &-button {
      position: absolute;
      right: 30px;
      bottom: 35px;
      display: flex;
      gap: 10px;

      &-icon {
        width: 45px;
        height: 45px;
        border: 1px solid #dddddd;
        border-radius: 2px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // @media (max-width: 991px) {
    //   display: none;
    // }
    @media (max-width: 575px) {
      display: none;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      padding: 30px;
      // width: calc(100% - 300px);
      // &-wrap {
      //   img {
      //     height: 400px;
      //   }
      // }
    }
  }

  &-details {
    width: 630px;

    box-shadow: 0px 3px 6px #00000029;
    // @media (max-width:576px) {
    //   display: none;
    // }

    //overflow-y: auto;
    // @media (max-width: 991px) {
    //   width: 100%;
    // }

    &-body {
      height: calc(100vh - 88px);
      overflow-y: auto;
      padding: 35px 30px 0px;

      @media (max-width: 767px) {
        padding: 40px 20px 0px;
      }

      &-close {
        margin-top: -10px;
        float: right;
        cursor: pointer;
        display: none;

        svg {
          width: 25px;
          height: 25px;
          padding: 5px;
        }
      }
    }

    &-header {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        width: 100%;

        .product-images-button-icon {
          width: 37px;
          height: 37px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &-logo {
      margin-bottom: 15px;
    }

    &-tagline {
      font-size: 14px;
    }

    &-sku {
      font-size: 14px;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-title {
      font-size: 25px;
      font-weight: Medium;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    &-pricewrap {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    &-price {
      font-size: 20px;
      margin-bottom: 0;
      font-weight: 600;
      margin-right: 26px;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-currancy {
      height: 20px;
      width: auto;
      font-size: 10px;
      padding: 0px 25px 0px 5px;
      border-radius: 0;
      box-shadow: none;
      outline: none;
      background-position: right 0.25rem center;
    }

    .dimensions {
      position: relative;
      margin-bottom: 30px;

      &-lable {
        span {
          font-size: 14px;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }

        margin-bottom: 15px;
      }

      &-input {
        padding: 17px 15px;
        font-size: 18px;
        color: $black-1;
        border: 1px solid $gray-4;
        border-radius: 2px;

        @media (max-width: 767px) {
          padding: 14px 12px;
          font-size: 14px;
        }
      }

      .nav {
        border: none;
        position: absolute;
        right: 0;
        top: 0;

        &-item {
          margin-left: 6px;
        }

        &-link {
          padding: 0;
          font-size: 10px;
          border-radius: 0;
          padding: 4px 7px;
          border: 1px solid $gray-4;
          border-radius: 2px;
          width: 30px;
          color: $black-1;

          &.active {
            background-color: $black-1;
            color: $white;
          }
        }
      }
    }

    .details {
      margin-bottom: 30px;

      &-title {
        margin-bottom: 10px;
        color: $black-1;
        font-size: 14px;
        font-weight: 500;

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }

      &-list {
        padding: 14px 0px;
        border-top: 1px solid $gray-5;

        &-title,
        &-value {
          font-size: 13px;
          color: $black-1;

          @media (max-width: 767px) {
            font-size: 10px;
          }
        }

        &-value {
          font-weight: 500;
        }

        .tabcontainerpackagedimension {
          display: flex;
          flex-direction: row;
          width: auto;
          justify-content: space-between;
          align-items: center;
          padding: 7px 5px;
          padding-right: 0;

          .tabcontent {
            margin: 0 5px;
          }

          .tabvalue {
            margin: 0 5px;
            margin-right: 0;
            flex-wrap: nowrap !important;
          }

          p {
            margin-bottom: 0;
          }

          .dimensions {
            margin-bottom: 0;
          }
        }
      }
    }

    .enquire {
      position: sticky;
      bottom: 0;
      // margin-left: -30px;
      // margin-right: -30px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 21px #0000001a;

      &-button {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        background-color: #fff;
      }

      // @media (max-width: 767px) {
      //   margin-left: -20px;
      //   margin-right: -20px;
      // }
    }
  }
}

.product-imagesm {
  position: relative;
  margin-bottom: 20px;

  &-button {
    position: absolute;
    right: 7px;
    bottom: 7px;

    &-icon {
      width: 37px;
      height: 37px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.product-images-wrap .image-gallery-fullscreen-button {
  position: absolute !important;
  bottom: -110px !important;
  // right: 0px !important;
  filter: none;
  border: 1px solid #DDDDDD;
}

@media (max-width:575px) {
  .product-images-wrap .image-gallery-fullscreen-button {
    // right: 0px !important;
    // bottom: 25px !important;
    display: none;
  }
}

@media only screen and (max-width: 991px), 
(height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape), 
(width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait)  {

  .product-images-wrap .image-gallery-fullscreen-button {
    // right: 0px !important;
    // bottom: 25px !important;
    display: none;
  }
}


.product-images-wrap .image-gallery-slide-wrapper {
  display: unset !important;
}

.white-space {
  white-space: nowrap !important;
}

.image-gallery-icon {
  filter:none;
}

.product-images-wrap .image-gallery-fullscreen-button svg {
  stroke: #555555;
}
@media (max-width:576px) {
  .enquire-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.enquire-mdl-body {
  top: 20px;
}

.loader-image {
  display: flex;
  height: 80px;
  width: auto;
  margin: auto;
}