.product-box {
  .form-group {
    display: block;
    margin-bottom: 15px;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #202027;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 2px;
    float: left;

    @media (max-width: 767px) {
      padding: 6px;
      margin-right: 5px;
    }
  }

  .form-group input:checked + label:after {
    //background-color: #202027;
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    @media (max-width: 767px) {
      top: 0px;
      left: 5px;
      width: 6px;
      height: 11px;
    }
  }

  .form-group input:checked + label::before {
    background-color: #202027;
  }
}
