* {
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

input,
.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @include placeholder {
    font-size: 13px;
    color: $label;
  }
}
