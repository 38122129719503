.btn-black {
  font-size: 13px;
  color: $white;
  padding: 15px 30px;
  background-color: $black-1;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  &:hover {
    background-color: $black-1;
  }
  &:focus,
  &:focus-visible {
    background-color: $black-1;
  }
  &:active {
    background-color: $black-1;
  }
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
}
.btn-gray {
  font-size: 13px;
  color: $black-1;
  padding: 15px 30px;
  background-color: $gray-5;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  &:hover {
    background-color: $gray-5;
    color: $black-1;
  }
  &:focus,
  &:focus-visible {
    background-color: $gray-5;
    color: $black-1;
  }
  &:active {
    background-color: $gray-5;
    color: $black-1;
  }
  @media (max-width: 576px) {
    padding: 10px 20px;
  }
}
.btn-check:checked + .btn.btn-black,
.btn.btn-black.active,
.btn.btn-black.show,
.btn.btn-black:first-child:active,
:not(.btn-check) + .btn.btn-black:active {
  background-color: $black-1;
  border-color: $black-1;
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-check:checked + .btn.btn-gray,
.btn.btn-gray.active,
.btn.btn-gray.show,
.btn.btn-gray:first-child:active,
:not(.btn-check) + .btn.btn-gray:active {
  background-color: $gray-5;
  border-color: $gray-5;
  border: none;
  box-shadow: none;
  outline: none;
}
