.selections {
  position: fixed;
  right: 0px;
  top: 0;
  background: #fff;
  height: 100vh;
  z-index: 999;
  overflow-y: auto;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  &.is-open {
    display: block;
  }
  &.is-close {
    display: none;
  }
  &-header {
    padding: 22px 27px;
    font-size: 18px;
    color: $black-1;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-2;
    span {
      cursor: pointer;
    }
  }
  &-body {
    padding: 0px 26px;
  }
  &-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 0px;
    border-bottom: 1px solid $gray-2;
    &:last-child {
      border-bottom: none;
    }
    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border: 1px solid #ddd8dc;
      border-radius: 2px;
      margin-right: 10px;
    }
    &-title {
      font-size: 14px;
      color: $black-3;
      font-weight: 500;
    }
    &-comment {
      font-size: 12px;
      color: $black-2;
    }
    &-edit {
      font-size: 10px;
      color: $black-3;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .form {
    padding: 26px 26px;
    &-title {
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid $gray-2;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
.overlay {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.cart-modal .modal-dialog{
  position: fixed !important;
  right: 0px !important;
  top: 0px !important;
  transition:none !important;
}