.product-box {
  margin-bottom: 28px;
  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000001a;
    border: none;
    border-radius: 0;
    &-footer {
      padding: 20px;
      background-color: transparent;
      border-color: $gray-1;
      .form-check-input {
       
          margin-top: 0px;
        
        @media (max-width: 767px) {
          margin-top: 4px;
        }
      }
      label {
        font-size: 14px;
        span {
          &:first-child {
            color: $black-2;
            margin-right: 5px;
          }
          &:last-child {
            color: $black-3;
          }
        }
      }
    }
  }
  &-body {
    padding: 0px;
  }
  // &-image {
  //   padding: 20px;
  // }

  @media (max-width: 767px) {
    .card {
      &-footer {
        padding: 14px;
      }
    }
  }
}

.selected-product {
  width: 188px;
  height: 79px;
  box-shadow: 0px 0px 35px #1e1a2233;
  border-radius: 55px 0px 0px 55px;
  display: flex;
  align-items: center;
  padding: 18px;
  position: fixed;
  bottom: calc(100vh - 400px);
  right: 0;
  z-index: 999;
  background: #fff;
  cursor: pointer;
  &-wrap {
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
    }
    p {
      margin: 0px;
      &:first-child {
        font-size: 15px;
        font-weight: 500;
        color: $black-1;
      }
      &:last-child {
        font-size: 13px;
        color: $gray-3;
      }
    }
  }
  @media (max-width: 767px) {
    width: 152px;
    height: 55px;
    padding: 12px;
    bottom: calc(100vh - 230px);
    &-wrap {
      svg {
        width: 30px;
        height: 30px;
      }
      p {
        &:first-child {
          font-size: 12px;
        }
        &:last-child {
          font-size: 10px;
        }
      }
    }
  }
}
